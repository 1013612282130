'use strict';

(function($){

	// Mobile nav
	var MobileNavigation = (function() {
		
		var navigation = document.querySelector('.navigation__nav'),
		toggleNavBtn = document.querySelector('.navigation__toggle'),
		burger = document.querySelector('.navigation__toggle .burger'),
		navLinks = document.querySelectorAll('.navigation__nav-link'),
		dropdownArrows = document.querySelectorAll('.dropdown-arrow'),
		navLists = document.querySelectorAll('.navigation__nav-list'),
		body = document.querySelector('body'),
		html = document.querySelector('html');
		
		// Disable page scroll
		function disablePageScroll() {
			if (navigation.classList.contains('is-open')) {
				body.classList.add('_disableScroll');
				html.classList.add('_disableScroll');
			} else {
				body.classList.remove('_disableScroll');
				html.classList.remove('_disableScroll');
			}
		};
		
		// Nav function (toggle)
		function toggleNav() {
			navigation.classList.toggle('is-open');
			burger.classList.toggle('is-open');
			for (var i = 0; i < navLists.length; i++) {
				navLists[i].classList.remove('show');
			}
			for (var i = 0; i < dropdownArrows.length; i++) {
				dropdownArrows[0].classList.remove('is-open');
			}
			disablePageScroll();
		};
		
		// To default
		function toDefaults() {
			// Close nav menu
			navigation.classList.remove('is-open');
			burger.classList.remove('is-open');
			// Make sure scrolling is enabled
			body.classList.remove('_disableScroll');
			html.classList.remove('_disableScroll');
		}

		// Toggles submenu
		function toggleSubmenu(element) {
			element.toggleClass('is-open');
			element.next('.navigation__nav-list').toggleClass('show');
		}
		
		// Event listeners
		if (toggleNavBtn !== null) {
			toggleNavBtn.addEventListener('click', toggleNav);
		}
		
		// (on mobile) close nav menu when link is clicked
		// this is useful on mobile when clicking an anchor tag on the current page (eg. index.html#last-section)
		// for (var i = 0; i < navLinks.length; i++) {
		// 	navLinks[0].addEventListener('click', function(event) {
		// 		toDefaults();
		// 	});
		// }

		// toggles submenu navigations when dropdown arrow is clicked
		for (var i = 0; i < dropdownArrows.length; i++) {
			dropdownArrows[i].addEventListener('click', function(event) {
				toggleSubmenu($(this));
			});
		}
		
		
		// when browser is resized (past breakpoint) reset to defaults
		(function() {
			
			window.addEventListener("resize", resizeThrottler, false);
			var resizeTimeout;
			
			function resizeThrottler() {
				// ignore resize events as long as an actualResizeHandler execution is in the queue
				if ( !resizeTimeout ) {
					resizeTimeout = setTimeout(function() {
						resizeTimeout = null;
						actualResizeHandler();
						
						// The actualResizeHandler will execute at a rate of 15fps
					}, 66);
				}
			}
			
			function actualResizeHandler() {
				// handle the resize event
				
				// Window resized width
				var width = window.innerWidth;
				
				// If resized greater than BREAKPOINT (default: 800px), then reset nav functions
				if (width >= 991) {
					toDefaults();
				}
			}
			
		}());
		
	})();

	// Fullscreen navigation on desktop
	var OverlayNavigation = (function() {
		var navigationOverlay  = $('.navigation-overlay');
		var navLinks = document.querySelectorAll('.navigation__nav > .navigation__nav-list > .navigation__nav-item > .navigation__nav-link--sidebar');
		
		for (var i = 0; i < navLinks.length; i++) {
			navLinks[i].addEventListener('click', function() {
				navigationOverlay.addClass('show');
				navigationOverlay.find('.navigation-overlay__section').removeClass('active');
				navigationOverlay.find('#' + $(this).data('nav-target')).addClass('active');
				togglePageScroll();
			});
		}
		$('.navigation-overlay__close').on('click', function() {
			navigationOverlay.removeClass('show');
			togglePageScroll();
		});

		// Toggle page scroll
		function togglePageScroll() {
			if (navigationOverlay.hasClass('show')) {
				$('body').addClass('_disableScroll');
				$('html').addClass('_disableScroll');
			} else {
				$('body').removeClass('_disableScroll');
				$('html').removeClass('_disableScroll');
			}
		};

		window.addEventListener('resize', function() {
			if (window.innerWidth <= 991) {
				navigationOverlay.removeClass('show');
				togglePageScroll();
			}
		});

	})();

	// Offfset Header
	var OffsetHeader = (function() {
		var headerHeight = $('.navigation').outerHeight();
		var section = $('.js-offset-header');

		if (window.innerWidth <= 991) {
			section.css('min-height', 'calc(100vh - ' + headerHeight +'px)');
		}
		
		window.addEventListener('resize', function() {
			headerHeight = $('.navigation').outerHeight();
			if (window.innerWidth <= 991) {
				section.css('min-height', 'calc(100vh - ' + headerHeight +'px)');
			} else {
				section.css('min-height', '100vh');
			}
		});
	})();

	// Omnisearch
	var Omnisearch = (function() {
		
		$("body").on("click", "[data-action]", function(e) {
			
			e.preventDefault();
			
			var $this = $(this);
			var action = $this.data('action');
			var target = $this.data('target');
			
			switch (action) {
				case 'omnisearch--open':
					target = $this.data('target');
					$(target).addClass('show');
					$(target).find('.form-control').focus();
					$('body').addClass('omnisearch--open').append('<div class="mask mask--dark" data-action="omnisearch--close" data-target="'+target+'" />');
					break;
				
				case 'omnisearch--close':
					target = $this.data('target');
					$('[data-action="search--open"]').removeClass('active');
					$(target).removeClass('show');
					$('body').removeClass('omnisearch--open').find('.mask').remove();
					break;
			}

		});

	})();

	// Slider
	var Slider = (function() {
		$(document).ready(function () {

			// Hero slider
			var heroSlider = new Swiper ('.slider--hero .swiper-container', {
				// Optional parameters
				direction: 'horizontal',
				speed: 300,
				loop: false,
				effect: 'fade',
				allowTouchMove: false,

				autoplay: {
					delay: 5000,
				},

				// If we need pagination
				pagination: {
					el: '.slider__pagination-wrapper .swiper-pagination',
					clickable: true,
					type: 'bullets',
				},

				breakpoints: {
					// when window width is <= 991px
					991: {
						allowTouchMove: true
					}
				}
			})

			// Events Slider
			var eventsSlider = new Swiper ('.slider--events .swiper-container', {
				// Optional parameters
				direction: 'horizontal',
				speed: 600,
				loop: false,
				shortSwipes: false,
				longSwipesRatio: .1,
				longSwipesMs: 200,
				spaceBetween: 20,

				// If we need pagination
				pagination: {
					el: '.swiper-fraction-navigation .swiper-pagination',
					type: 'fraction',
					clickable: true,
					formatFractionCurrent: function (number) {
						if (number < 10) return '0' + number;
						else return number;
					},
					formatFractionTotal: function (number) {
						if (number < 10) return '0' + number;
						else return number;
					}
				},
				navigation: {
					prevEl: '.swiper-custom-prev',
					nextEl: '.swiper-custom-next',
				}
			})

			// Image Gallery Slider
			var imageGallerySlider = new Swiper ('.slider--image-gallery .swiper-container', {
				// Optional parameters
				direction: 'horizontal',
				speed: 600,
				loop: false,
				shortSwipes: false,
				longSwipesRatio: .1,
				longSwipesMs: 200,

				// If we need pagination
				pagination: {
					el: '.swiper-fraction-navigation .swiper-pagination',
					type: 'fraction',
					clickable: true,
					formatFractionCurrent: function (number) {
						if (number < 10) return '0' + number;
						else return number;
					},
					formatFractionTotal: function (number) {
						if (number < 10) return '0' + number;
						else return number;
					}
				},
				navigation: {
					prevEl: '.swiper-custom-prev',
					nextEl: '.swiper-custom-next',
				}
			})


		});
	})();

	// Trails filtering
	var TrailFilter = (function() {
		var $container = $('.js-trails-container'),
			$checkboxes = $('.js-trails-checkbox');
		
		$container.isotope({
			itemSelector: '.js-trails-item',
			transitionDuration: '0',
			hiddenStyle: {
				opacity: 0,
				transform: 'translateX(-200px)'
			},
			visibleStyle: {
				opacity: 1,
				transform: 'translateX(0)'
			}
		});
		
		$checkboxes.change(function(){
			var filters = [];
			// get checked checkboxes values
			$checkboxes.filter(':checked').each(function(){
				filters.push( this.value );
			});
			// ['.trail-beginner', '.trail-intermediate'] -> '.trail-beginner, .trail-intermediate'
			filters = filters.join(', ');
			$container.isotope({ filter: filters });
		});

		// Shows message when there are no results
		$container.on( 'arrangeComplete', function( event, filteredItems ) {
			var resultCount = filteredItems.length;
			if(resultCount == 0) {
				$('.filter-message').show();
			} else {
				$('.filter-message').hide();
			}
		});

	})();

	// Scripts for events list
	var EventsList = (function() {
		// Removes autocomplete from datepicker input
		$('#tribe-bar-date').attr('autocomplete', 'off');
		
		// Clears filters
		$('.js-events-clear-filters').click( function(e) {
			e.preventDefault();
			$('#tribe-bar-form').tribe_clear_form();
		})

	})();

	// Gravity Forms
	var Forms = (function() {
		// Makes Gravity Forms custom select fields to have a border when focused
		$('.gfield_select').focus(function() {
			$(this).parent().css('border', '1px solid #1567ee'); 
		}); 
		$('.gfield_select').focusout(function() {
			$(this).parent().css('border', '1px solid #ced4da'); 
		}); 
	})();

	// Initializes tooltips
	var Tooltips = (function() {
		$('[data-toggle="tooltip"]').tooltip()
	})();

})(jQuery)